import Api from 'utilities/api';
import { fetchAsync } from 'actions/AsyncAction';

const prefix = `approval_flows`;

export function fetchApprovalFlows() {
  return async (dispatch, getState) => {
    const limit = 100;
    let offset = 0;
    const firstData = await dispatch(fetchAsync(Api.approvalFlows.index, { limit, offset }));
    let approvalFlows = firstData.approvalFlows.map((flow) => ({
      ...flow,
      key: flow.id,
    }));
    dispatch(setApprovalFlows(approvalFlows)); // 最初のn件を表示しておく

    // n件以降のデータは分割して取得する
    const max = firstData.count;
    let iter = 0;
    const iterable = [];
    while (offset + limit < max) {
      iter++;
      offset = limit * iter;
      iterable.push(dispatch(fetchAsync(Api.approvalFlows.index, { limit, offset })));
    }

    const results = await Promise.all(iterable);
    results.forEach((data) => {
      const appending = data.approvalFlows.map((flow) => ({
        ...flow,
        key: flow.id,
      }));
      approvalFlows = [...approvalFlows, ...appending];
    });

    dispatch(setApprovalFlows(approvalFlows));
  };
}

export function fetchAttachedStatus() {
  return async (dispatch, getState) => {
    const data = await dispatch(fetchAsync(Api.approvalFlows.attachedStatus));
    dispatch(setAttachedStatus(data.data));
  };
}

export const SET_APPROVAL_FLOWS = `${prefix}/SET_APPROVAL_FLOWS`;
function setApprovalFlows(approvalFlows) {
  return {
    type: SET_APPROVAL_FLOWS,
    approvalFlows,
  };
}

export const SET_ATTACHED_STATUS = `${prefix}/SET_ATTACHED_STATUS`;
function setAttachedStatus(attachedStatus) {
  return {
    type: SET_ATTACHED_STATUS,
    attachedStatus,
  };
}
