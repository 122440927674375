import React from 'react';
import styled from 'styled-components';
import colors from 'values/colors';
import { TableHeaderProps } from 'react-table';

interface WrapperProps {
  readonly isSimpleDesignMode: boolean;
}
const Wrapper = styled.th<WrapperProps>`
  margin: 0;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: ${(p): string => (p.isSimpleDesignMode ? '9px 8px 7px' : '8px 12px 8px 12px')};
`;
interface BarProps {
  readonly isReportItemDesignMode: boolean;
}
/**
 * NOTE: FireFoxでthのborderが表示されないための縦線
 */
const LeftBar = styled.div<BarProps>`
  position: absolute;
  height: 100%;
  width: 1px;
  top: 0;
  left: -0.5px;
  background: ${(p): string => (p.isReportItemDesignMode ? "#cccccc" : "#dddddd")};
`;
interface BottomBarProps {
  readonly isReportItemDesignMode: boolean;
}
/**
 * NOTE: FireFoxでthのborderが表示されないための横線
 */
const BottomBar = styled.div<BottomBarProps>`
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: ${(p): string => (p.isReportItemDesignMode ? "#cccccc" : colors.renewaled.whiteHover)};
`;

interface Props {
  readonly isSimpleDesignMode: boolean;
  /** 横線をつけるか */
  readonly isBorderLeft: boolean;
  /** 請求書刷新でのテーブルデザイン */
  readonly isReportItemDesignMode: boolean;
  readonly children: JSX.Element | JSX.Element[];
  /** react-tableのdomのprops */
  readonly tableHeaderProps: TableHeaderProps;
}

/**
 * table: th
 */
export const Th: React.FC<Props> = (props: Props) => {
  const className = `th${
    props.tableHeaderProps.className
      ? ` ${props.tableHeaderProps.className}`
      : ""
  }`;
  const style = {
    ...props.tableHeaderProps.style,
    maxWidth: props.tableHeaderProps?.style?.width || "auto",
    minWidth: "10px",
    display: "table-cell",
  };

  return (
    <Wrapper
      {...props.tableHeaderProps}
      className={className}
      isSimpleDesignMode={props.isSimpleDesignMode}
      key={props.tableHeaderProps.key}
      role={props.tableHeaderProps.role}
      style={style}
    >
      { !props.isSimpleDesignMode && (
        <>
          { props.isBorderLeft && (
            <LeftBar
              isReportItemDesignMode={ props.isReportItemDesignMode }
              className='th-border-left-bar'
            />
          ) }
          <BottomBar
            isReportItemDesignMode={ props.isReportItemDesignMode }
            className='th-border-bottom-bar'
          />
        </>
      )}
      { props.children }
    </Wrapper>
  );
};
