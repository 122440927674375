import Tag from "applications/tags/components/Tag";
import TagSelector from "applications/tags/components/TagSelector";
import { useFetcher } from "hooks/useFetcher";
import i18next from "i18n";
import React, { FC, useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import styled from "styled-components";
import ApiPaymentRequests from "utilities/api_payment_requests";
import { ReportLabel } from "utilities/api_payment_requests/models";

type LabelFormProps = {
  container?: HTMLElement | null;
  selection: ReportLabel[];
  placement?: string;
  disabled?: boolean;
  onReportLabelsChange?: (labels: ReportLabel[]) => void;
};

const InputLikeView = styled.div<{ disabled?: boolean }>`
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  padding: 6px 12px;
  .txt {
    color: #555555;
  }
  ${({ disabled }): string =>
    disabled
      ? `background-color: #eeeeee; opacity: 1; cursor: not-allowed;`
      : ""};
`;

const LabelContainerDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 4px;
`;

const ScrollablePopover = styled(Popover)`
  .popover-content {
    overflow-y: auto;
    max-height: 250px;
  }
`;

type ReportLabelWithSelectStatus = ReportLabel & { selected: boolean };

export const ReportLabelField: FC<LabelFormProps> = ({
  container = null,
  placement = "top",
  selection,
  disabled,
  onReportLabelsChange,
}) => {
  const { fetch, response } = useFetcher(ApiPaymentRequests.reportLabels.index);
  const [labels, setLabels] = useState<ReportLabelWithSelectStatus[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (response) {
      setLabels(
        response.labels.map((lab) => ({
          ...lab,
          selected: selection.some((sel) => sel.id === lab.id),
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response, selection]);

  const handleSelectLabel = useCallback(
    (label: ReportLabel, selected: boolean) => {
      if (onReportLabelsChange === undefined) return;

      const newLabels = labels.map((lab) => {
        if (lab.id === label.id) return { ...lab, selected };
        return lab;
      });

      onReportLabelsChange(newLabels.filter((lab) => lab.selected));
      setLabels(newLabels);
    },
    [labels, onReportLabelsChange],
  );

  const renderLabels = (reportLabels: ReportLabel[]): JSX.Element => {
    if (reportLabels.length === 0) {
      return <span className="txt">{i18next.t("commons.actions.unset")}</span>;
    }

    return (
      <LabelContainerDiv>
        {reportLabels.map((label) => (
          <Tag
            key={`label-${label.id}`}
            containerStyle={{ margin: "4px 0" }}
            name={label.name}
            color={label.color}
          />
        ))}
      </LabelContainerDiv>
    );
  };

  if (disabled) {
    return <InputLikeView disabled>{renderLabels(selection)}</InputLikeView>;
  }

  return (
    <OverlayTrigger
      trigger="click"
      placement={placement}
      rootClose
      container={container}
      overlay={
        <ScrollablePopover
          id="label-popup"
          title={i18next.t("members.titles.selectLabel")}
        >
          <TagSelector tags={labels} onClickTag={handleSelectLabel} />
        </ScrollablePopover>
      }
    >
      <InputLikeView className="txt-pointer">
        {renderLabels(selection)}
      </InputLikeView>
    </OverlayTrigger>
  );
};
