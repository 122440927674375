import React from "react";
import { Modal as BootstrapModal } from "react-bootstrap";
import styled from "styled-components";
import fonts from "values/fonts";

interface Props {
  /** モーダルの横幅 */
  readonly width?: "lg" | "md" | "sm" | "xl" | "auto" | "full";
  /**
   * 高さを超えた場合のover-flow-y
   * 2カラム表示の場合はhiddenにしておく必要あり。
   */
  readonly overflowY?: "auto" | "hidden";
  /** ヘッダーのボーダーを非表示にするか */
  readonly hideHeaderBorder?: boolean;
  /** Bodyの中身をモーダル全体に表示するか(bodyのpaddingを無くす) */
  readonly fillContent?: boolean;
  /** 表示するか */
  readonly show: boolean;
  /** モーダル外をクリックした際に閉じるか */
  readonly backdrop?: boolean;
  /** ローディング中か */
  readonly isLoading?: boolean;
  /** CSSクラス名を追加 */
  readonly className?: string;
  /** 非表示にした */
  readonly onClose: () => void;
  /** 表示にした */
  readonly onShow?: () => void;
}

/**
 * 画面横幅の一覧
 */
const widthOptions = {
  sm: 600,
  md: 800,
  lg: 1000,
  xl: 1200,
  full: "100%",
};

/**
 * モーダルのレイアウトCSS
 */
const BootstrapModalView = styled(
  ({
    /**
     * styled-componentsに独自のpropsを与えた際に、キャメルケースが含まれるとyarn testで落ちてしまう。
     * @see https://styled-components.com/docs/faqs#why-am-i-getting-html-attribute-warnings
     * 上記に従って構文を変えるとテストは通るが、恐らくeslintのバグで'定義した変数が使われていない'とwarningが出てしまうため、eslint-disable挿入。
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hideHeaderBorder,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fillContent,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    overflowY,
    ...props
  }) => <BootstrapModal {...props} />,
)`
  background: hsl(210deg 50% 80% / 40%);
  backdrop-filter: blur(5px);

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .modal-dialog {
    animation: fadeIn 100ms ease 1;
    z-index: 10;
    margin-top: 40px;
    width: calc(100vw - 40px);
    max-width: ${({ width }): string =>
      !width || width === "auto" ? "auto" : `${widthOptions[width]}px`};
  }

  .modal-content {
    border: none;
    box-shadow: none;
  }

  .modal-header {
    box-shadow: ${({ hideHeaderBorder }): string =>
      hideHeaderBorder ? "none" : "0 2px 8px -3px rgba(0, 0, 0, 0.1)"};
    z-index: 30;
    position: relative;
    padding: 13px 16px 11px;
    font-weight: bold;
    border: none;
    button {
      margin: 0;
      padding: 0 8px;
    }
  }

  .modal-title {
    font-weight: ${fonts.weight.bold};
  }

  .modal-body {
    z-index: 20;
    overflow-y: ${(props): string => props.overflowY || "auto"};
    height: 100%;
    max-height: calc(100vh - 200px);
    position: relative;
    padding: ${(props): string => (props.fillContent ? "0" : "16px")};
  }

  .modal-footer {
    z-index: 30;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 8px;
    position: relative;
    border: none;
    box-shadow: 0 -2px 8px -3px rgba(0, 0, 0, 0.1);
  }

  .overlay {
    z-index: 40;
    position: fixed;
    top: 0px;
    bottom: 0;
    width: ${({ width }): string =>
      !width || width === "auto" ? "auto" : `${widthOptions[width]}px`};
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    > i {
      font-size: 32px;
      color: #808080;
    }
  }
`;

/**
 * モーダル
 */
export const Modal: React.FC<Props> = ({
  show,
  backdrop = true,
  isLoading = false,
  width = "md",
  onClose,
  onShow,
  overflowY,
  children,
  hideHeaderBorder,
  fillContent,
  className,
}) => {
  if (!show) return null;

  return (
    <BootstrapModalView
      show={show}
      onHide={onClose}
      onShow={onShow}
      backdrop={backdrop || "static"}
      animation={false}
      width={width}
      overflowY={overflowY}
      hideHeaderBorder={hideHeaderBorder}
      fillContent={fillContent}
      className={`renewaled-modal ${className}`}
    >
      {children}
      {isLoading && (
        <div className="overlay">
          <i className="fas fa-lg fa-spin fa-spinner loading-icon" />
        </div>
      )}
    </BootstrapModalView>
  );
};
