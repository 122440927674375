import { PDFTextLayerWrapperCSS } from "components/renewaled_ui/FileViewer/Viewer/PDF/parts/TextLayer";
import { Modal } from "react-bootstrap";
import styled, { css } from "styled-components";
import { isSafari } from "utilities/Utils";
import colors from "values/colors";
import fonts from "values/fonts";

// 画像反転スイッチのパラメータ
const SWITCH_SIZE = 22;
const SWITCH_MARGIN = 2;
const SLIDE_MARGIN = 2;
const LABEL_MARGIN = 11;
const SLIDE_SPACE = 50 - SLIDE_MARGIN - LABEL_MARGIN;
const TRANSITION_DURATION = "0.4s";

function normalizeSize(size?: number | string): string {
  if (typeof size === "number") {
    return `${size}px`;
  }
  if (size) {
    return size;
  }

  return "auto";
}

const widthTransitionStyle = css`
  transition: width 300ms ease;
`;

/**
 * 業務エリアとReactDOM.renderが分かれているため、サイドバー内でstyled-componentsのスタイルが当たらない。
 * その為、styled-componentsのスタイルをここでのみ再指定する。
 * TODO: 全体でReactDOM.renderが統一された際にここでのスタイル指定は全て削除する。
 */
export const ReportCreateModalView = styled(Modal)`
  background: hsl(210deg 50% 80% / 40%);
  backdrop-filter: blur(5px);

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  // モーダル react-bootstrap
  .modal-dialog {
    animation: fadeIn 200ms ease 1;
    z-index: 10;
    margin-top: 40px;
    width: calc(100% - 80px);
    max-width: 1800px;
  }

  .modal-content {
    border: none;
    box-shadow: none;
  }

  .modal-header {
    position: relative;
    padding: 13px 16px 11px;
    font-weight: bold;
    border: none;
    box-shadow: none;
    button {
      margin: 0;
      padding: 0 8px;
    }
  }

  .modal-title {
    font-weight: ${fonts.weight.bold};
  }

  .modal-body {
    z-index: 20;
    overflow: hidden;

    position: relative;
    padding: 0;
  }

  .modal-footer {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 8px;
    position: relative;
    border: none;
    box-shadow: 0 -2px 8px -3px rgba(0, 0, 0, 0.1);
  }

  // タブ /components/renewaled_ui/Tab/Tabs.tsx
  .renewaled-tabs {
    display: flex;
    border-bottom: 1px solid ${colors.renewaled.borderGrey};
    padding-left: 8px;

    .renewaled-tab {
      transition: 100ms;
      margin-left: 4px;
      user-select: none;
      padding: 8px 12px;
      border-radius: 8px 8px 0 0;
      font-weight: ${fonts.weight.bold};
      position: relative;
      cursor:  pointer;
      background: ${colors.renewaled.whiteHover};
      color: inherit;
      border-top: 1px solid transparent;
      border-right: 1px solid transparent;
      border-left: 1px solid transparent;

      &::after {
        background: transparent;
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        bottom: -1px;
        left: 0;
      }
    }

    .renewaled-tab-selected {
      cursor: auto;
      background: #fff;
      color: ${colors.renewaled.main};
      border-top: 1px solid ${colors.renewaled.borderGrey};
      border-right: 1px solid ${colors.renewaled.borderGrey};
      border-left: 1px solid ${colors.renewaled.borderGrey};

      &::after {
        background: #fff;
      }
    }
  }

  // ボタン /components/renewaled_ui/buttons/Button.tsx
  .renewaled-button {
    min-width: 120px;
    border: none;
    outline: none;
    border-radius: 8px;
    padding: 6px 16px 4px; // 文字が上下中心になる設定
    transition: 150ms;
    background: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-weight: ${fonts.weight.bold};
    &.primary {
      color: #fff;
      background: linear-gradient(180deg, #336bcc 0%, #004acc 100%);
      box-shadow: 0px 0.5px 1.5px rgba(56, 127, 255, 0.25);
      &:hover, &:focus {
        box-shadow: none;
        filter: brightness(95%);
      }
    }
    &.link-primary {
      color: #004ACC;
      &:hover, &:focus {
        background: #EDF4FF;
      }
    }
    &.link-secondary {
      color: ${colors.renewaled.textBlack};
      &:hover, &:focus {
        background: #EFEFEF;
      }
    }
    &.link-text {
      padding: 0;
      transition: 150ms;
      &:hover, &:focus {
        background: inherit;
        text-decoration: underline;
      }
    }
  }

  .overlay {
    z-index: 40;
    position: fixed;
    top: 0px;
    bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    > i {
      font-size: 32px;
      color: #808080;
    }
  }
  .size-per-page-drop-down-button {
    min-width: auto;
  }

  // テーブル /components/renewaled_ui/table_views/Table.tsx
  .table-component-wrapper {
    overflow: auto;
    max-height: calc(100vh - 380px);
    position: relative;
    line-height: 23px;
    color: ${colors.renewaled.textBlack};
  }
  .table-component {
    .thead {
      position: sticky;
      top: 0;
      font-weight: 600;
      z-index: 2;
      :hover {
        .resizer {
          transition: 500ms;
          background: #d0d3d3;
        }
      }
      .resizer {
        display: inline-block;
        width: 14px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        &.isResizing {
          transition: 100ms;
          background: #bcc0c0;
        }
      }
      .tr {
        background: ${colors.renewaled.whiteHover};
      }
    }
    .tbody {
      font-weight: ${fonts.weight.bold};
      .tr {
        border-bottom: 1px solid ${colors.renewaled.borderGrey};
        cursor: pointer;
        transition: 50ms;
        text-decoration: none;
        color: inherit;
        :hover {
          background: ${colors.renewaled.whiteHover};
        }
        &.isSelected {
          background: ${colors.renewaled.whiteHoverActive};
        }
        :last-child {
          .td {
            border-bottom: 0;
          }
        }
      }
    }
    .th,
    .td {
      margin: 0;
      padding: 9px 8px 7px;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .th-border-left-bar {
      position: absolute;
      height: 100%;
      width: 1px;
      top: 0;
      left: -0.5px;
      background: #ddd;
    }
    .th-border-bottom-bar {
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: 0;
      left: 0;
      background: #f4f4f4;
    }
    .table-component-sort-icon {
      margin-left: 8px;

      i {
        color: #c5c8c9;
      }
    }
    .table-component-no-data-td {
      padding: 16px 8px;
      text-align: center;
      background: white;
      justify-content: center;
    }
  }

  .renewaled-tab-panel-2 {
    .table-component {
      max-height: calc(100vh - 300px);
    }
  }

  .table-bottom-tool-bar {
    margin-top: 8px;
    display: flex;
    align-items: center;
    position: relative;

    .table-page-display {
      display: flex;
      align-items: center;
    }
  }

  // ページネーション /components/renewaled_ui/table_views/Pagination.tsx
  .table-pagination {
    display: flex;
    align-items: center;
    color: ${colors.renewaled.textBlack};
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .button-group {
      display: flex;
      align-items: center;
      margin-right: 24px;
      button {
        margin-right: 2px;
        i {
          font-size: 24px;
        }
      }
    }
    .current-page {
      font-weight: 600;
      margin-right: 24px;
      font-size: 12px;
    }
    .page-input {
      font-weight: 600;
      display: flex;
      align-items: center;
      .page-input-text {
        font-size: 12px;
        &.ja {
          margin-left: 4px;
        }
        &.en {
          margin-right: 4px;
        }
      }
      input {
        background: ${colors.renewaled.whiteHover};
        text-align: right;
        width: 70px;
        padding: 4px;
        border: none;
        border-radius: 4px;
        margin-right: 2px;
        &:focus {
          outline: none;
        }
      }
    }

    /* ページネーションのアイコンボタン /components/renewaled_ui/buttons/IconButton.tsx */
    .icon-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      transition: 150ms;
      border: none;
      outline: none;
      background: none;
      &:hover {
        background: ${({ disabled }): string =>
          disabled ? "inherit" : colors.renewaled.whiteHover};
      }
      i {
        color: ${({ disabled }): string =>
          disabled ? "#c5c8c9" : colors.renewaled.textBlack};
        position: relative;
        font-size: 20px !important;
        transition: 300ms;
      }
      &.disabled {
        &:hover {
          background: inherit;
        }
        i {
          color: #c5c8c9;
        }
      }
    }
  }

  // テーブルのチェックボックス /components/renewaled_ui/table_views/IndeterminateCheckbox.tsx
  .indeterminate-checkbox {
    .label {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    padding: 0;
    position: relative;
    width: auto;
    &::before {
      background: #fff;
      border: 1px solid #c5c8c9;
      border-radius: 4px;
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      height: 19px;
      width: 19px;
      top: 0px;
      left: -7px;
    }
    &::after {
      border-right: 2px solid white;
      border-bottom: 2px solid white;
      content: '';
      display: block;
      width: 7px;
      height: 10px;
      left: -1px;
      top: 3px;
      opacity: 0;
      position: absolute;
      z-index: 1;
      transform: rotate(45deg);
    }
    }
    input[type=checkbox] {
      display: none;
    }
    input[type=checkbox]:checked + .label::before {
      background: #327dff;
    }
    input[type=checkbox]:checked + .label::after {
      opacity: 1;
    }
    input[type=checkbox]:indeterminate + .label::before {
      background: #c5c8c9;
    }
    input[type=checkbox]:indeterminate + .label::after {
      opacity: 1;
      transform: rotate(0);
      width: 13px;
      height: 3px;
      background: white;
      border: none;
      border-radius: 2px;
      top: 8px;
      left: -4px;
    }
  }

  // フォーム
  .expense-entry-forms {
    display: flex;
    align-items: flex-start;
    max-width: 1000px;
    margin: 0 auto;
    .left-area {
      width: 67%;
      padding-right: 8px;
      margin-right: 24px;
      max-height: calc(100vh - 150px);
      overflow-y: auto;
    }
    .right-area {
      width: 33%;
      max-height: calc(100vh - 150px);
      overflow-y: auto;
      padding-right: 8px;
    }
    .full-area {
      width: 100%;
    }
  }

  // フォームのラベル
  .list-form {
    margin-bottom: 20px;
    .label-text {
      display: flex;
      font-weight: ${fonts.weight.bold};
      margin: 0;
      .required-icon {
        color: red;
        font-weight: 400;
        margin-left: 2px;
      }
    }
  }

  // アコーディオン化するフォームのラベル
  .accordion-list-form {
    margin-bottom: 20px;

    &.close-mode.edit-mode {
      border-bottom: solid 2px #dedcdc;
    }

    &.open-mode {
      .accordion-body {
        max-height: 500px;
      }
    }

    .accordion-top-label {
      display: flex;
      cursor: pointer;
      margin-bottom: 0px;
      background: none;
      font-weight: bold;
      padding: 0px;
      border-radius: 0px;
      .required-icon {
        color: red;
        font-weight: 400;
        margin-left: 2px;
      }
    }

    .accordion-body {
      padding-left: 0px;
      margin-bottom: 0px;
      max-height: 0px;
      overflow: hidden;
      transition: max-height 0.3s;

      .label-text {
        display: flex;
        font-weight: bold;
        background: none;
        padding: 0px;
        border-radius: 0px;
        .required-icon {
          color: red;
          font-weight: 400;
          margin-left: 2px;
        }
      }
    }
  }

  // 発着地フォーム
  .origin-and-destination-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    i {
      padding: 5px 16px;
      color: #757575;
      &:hover {
        color: #f39c12;
        cursor: pointer;
      }
    }
  }

  // 汎用マスタ
  .generic-fields-view {
    .generic-fields-items-list {
      margin-top: 8px;

      ul {
        list-style: none;
        padding-left: 8px;
      }
    }
  }

  // 適格請求書確認
  .eligible-invoice-confirmation-view {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    background: #f0f0f0;

    .registrated-number-area {
      padding-left: 0px;

      .registrated-number-title-area {
        margin-bottom: 0px;
        display: flex;

        .registrated-number-verify-result {
          margin-left: 12px;
          margin-right: 12px;
        }

        .invoicing-organization-process-detail {
          margin-top: 0px;
        }
      }

      .registrated-number-content-area {
        .non-style-ul {
          list-style: none;
          white-space: pre-wrap;
          padding-left: 0px;
          padding-top: 5px;
        }
      }
    }
  }

  .input-field {
    text-align: center;
    transition: background 0.3s;
    word-wrap: break-word;
    overflow: auto;
    height: auto;

    &--active:hover {
      background: ${colors.whiteHover};
      cursor: pointer;
    }

    &.disabled {
      cursor: auto;
      background: ${colors.whiteHover};
    }
  }

  .input-with-dropdown-button {
    width: 67px;
    height: 100%;
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 4px 0 0 4px;
    span {
      margin-left: 8px;
    }
  }

  .file-view-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;

    opacity: auto;
    backface-visibility: hidden;

    object {
      pointer-events: auto;
    }

    &.foreside {
      transform: rotateY(0deg);
    }
    &.backside {
      transform: rotateY(180deg);
    }
  }

  .control-bar {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    > * {
      padding: 0 8px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .flippable-card {
    position: relative;
    height: 500px;
    transition: transform 0.6s;
    transform: rotateY(0deg);
    transform-style: preserve-3d;
    &.flipped {
      transform: rotateY(180deg);
    }
  }

  .container-view {
    border-radius: ${SWITCH_SIZE / 2 + SWITCH_MARGIN}px;
    overflow: hidden;

    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), 0 1px 0 rgba(255, 255, 255, 0.2);
    width: ${(): string =>
      `${0 + LABEL_MARGIN + SLIDE_MARGIN + SLIDE_SPACE}px`};
    min-width: 75px; // ラベルなしの時に、スイッチの幅が潰れないように

    background: #eee;
    color: #3264c4;

    // 計算・表示を簡単にするため、デフォルトのスタイルをクリアする
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;

    transition: background ${TRANSITION_DURATION} ease, color ${TRANSITION_DURATION} ease;

    &:hover {
      cursor: pointer;
    }

    &.checked {
      background: #3264c4;
      color: white;
    }
  }

  .check-box {
    visibility: hidden;
    position: absolute;
    outline: none;
    margin: 0 !important; // Bootstrapのスタイルを上書き

    background: #fcfff4;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  }

  .label-view-active {
    white-space: nowrap;
    text-align: left;
    // ラベルとスイッチの間のスペースを調整
    margin-right: ${(): string => `${SLIDE_MARGIN + SLIDE_SPACE / 2}px`};
    margin-left: ${(): string => "0"};
  }

  .label-view-inactive {
    white-space: nowrap;
    text-align: right;
    // ラベルとスイッチの間のスペースを調整
    margin-right: ${(): string => "0"};
    margin-left: ${(): string => `${SLIDE_MARGIN + SLIDE_SPACE / 2}px`};
  }

  .switch-view {
    width: ${(): string =>
      `${2 * ((25.2 || 0) + SLIDE_MARGIN) + SLIDE_SPACE}px`};

    display: flex;
    align-items: center;
    user-select: none;

    height: ${SWITCH_SIZE + SWITCH_MARGIN * 2}px;
    transform: ${(): string => `translateX(-${25.2 + SLIDE_MARGIN}px)`};

    transition: transform ${TRANSITION_DURATION} ease;

    ::before {
      content: '';

      position: absolute;
      left: 50%;
      transform: ${(): string =>
        `translateX(calc(-50% - ${
          (SLIDE_SPACE - SWITCH_SIZE) / 2 - SWITCH_MARGIN
        }px))`};

      width: ${SWITCH_SIZE}px;
      height: ${SWITCH_SIZE}px;
      border-radius: ${SWITCH_SIZE / 2}px;

      background: #fcfff4;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);

      transition: transform ${TRANSITION_DURATION} ease;
    }

    &.active {
      transform: ${(): string => `translateX(${LABEL_MARGIN}px)`};

      ::before {
        transform: ${(): string =>
          `translateX(calc(-50% + ${
            (SLIDE_SPACE - SWITCH_SIZE) / 2 - SWITCH_MARGIN
          }px))`};
      }
    }

    > .label-view-active, > .label-view-inactive {
      width: ${(): string => `${25.2}px`};
      min-width: ${(): string => `${25.2}px`};
    }
  }

  // PDFビューアー: ブラウザ既存版
  .viewer-browser-pdf-wrapper {
    position: relative;
    overflow: hidden;
    background: #69696c;
    display: flex;
    flex-flow: column;
  }
  .viewer-browser-pdf-wrapper-loading {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    z-index: 100;
  }
  .viewer-browser-pdf-wrapper-loading-icon {
    opacity: 0.4;
    font-size: 50px;
  }
  .viewer-browser-pdf-wrapper-content {
    flex-grow: 1;
  }
  .viewer-browser-pdf-toolbar-toggle-wrapper {
    background: #6b6b6b;
    border-radius: 50px;
    width: 18px;
    height: 18px;
    box-shadow: 0 0 10px #00000052;
    cursor: pointer;
  }
  .viewer-browser-pdf-toolbar-toggle-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  // ブラウザ版PDFビューアーのツールバー
  .viewer-browser-pdf-toolbar-wrapper-outer {
    color: #ffffffe0;
  }
  .viewer-browser-pdf-toolbar-wrapper {
    overflow: hidden;
    height: 35px;
  }
  .viewer-browser-pdf-toolbar-main-bar {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    padding: 0 10px;
    background: #2e2e38;
    transition: 0.2s;
  }
  .viewer-browser-pdf-toolbar-left {
    display: flex;
    align-items: center;
    width: 50%;
  }
  .viewer-browser-pdf-toolbar-upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #294690;
    padding: 5px 10px;
    border-radius: 4px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  .viewer-browser-pdf-toolbar-spacer {
    width: 10px;
  }
  .viewer-browser-pdf-toolbar-download-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #294690;
    color: #ffffffe0;
    padding: 5px 10px;
    border-radius: 4px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      color: #ffffffe0;
      opacity: 0.8;
    }
    &:focus {
      color: #ffffffe0;
      opacity: 0.8;
    }
  }
  .viewer-browser-pdf-toolbar-file-name {
    font-size: 12px;
    width: 120px;
    opacity: 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .viewer-browser-pdf-toolbar-right {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
    width: 50%;
  }
  .viewer-browser-pdf-toolbar-delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #b63939;
    padding: 5px 10px;
    border-radius: 4px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  .viewer-browser-pdf-toolbar-right-space {
    width: 25px;
  }
  .viewer-browser-pdf-toolbar-bar-height {
    height: 35px;
  }
  .viewer-browser-pdf-toolbar-right-button {
    position: absolute;
    right: 8px;
    top: 8px;
  }
  // ブラウザ版PDFビューアーオブジェクト
  .viewer-browser-pdf-viewer-wrapper {
    width: 100%;
    height: 100%;
  }
  .viewer-browser-pdf-viewer-pdf-area {
    width: 100%;
    height: 100%;
  }
  .viewer-browser-pdf-viewer-pdf-error-area {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .viewer-browser-pdf-viewer-error-text {
    word-wrap: break-word;
    white-space: break-spaces;
    color: white;
    text-align: center;
    font-size: 14px;
  }
  .viewer-browser-pdf-viewer-change-button {
    background: #294790;
    border-radius: 4px;
    padding: 8px 0;
    margin-top: 10px;
    box-shadow: 0 0 5px 0px #00000045;
    cursor: pointer;
  }
  .viewer-browser-pdf-change-button-wrapper-outer {
    height: 100%;
    font-size: 10px;
  }
  .viewer-browser-pdf-change-button-wrapper {
    background: #0000003b;
    height: 100%;
    box-shadow: 0 0 10px #00000052;
    cursor: pointer;
    padding: 0 5px;
  }
  .viewer-browser-pdf-change-button-innner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
  }
  .viewer-browser-pdf-change-button-annotation {
    position: absolute;
    z-index: 10;
    padding: 5px;
    background: white;
    color: #333333;
    border-radius: 2px;
    box-shadow: 0 0 10px #000000ad;
    right: 10px;
  }

  // PDFビューアー: ライブラリ版
  .pdf-dropzone-view-wrapper {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    cursor: pointer;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
  }
  .pdf-dropzone-view-wrapper-inner {
    height: 100%;
  }
  .pdf-dropzone-view-wrapper-inner-content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    border: 4px dashed #aaa;
    opacity: 0;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.8;
    }
    & > p {
      font-weight: bold;
      font-size: 1.2em;
      text-align: center;
    }
  }
  .viewer-dropzone-view-footer {
    background-color: ${colors.main};
    position: absolute;
    bottom: 0;
    height: 32px;
    width: 100%;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: ${`${Math.floor(32 * 0.7)}px`};
    color: ${colors.white};
    opacity: 0.8;
    transition-duration: 2s;
    transition-timing-function: step-end;

    &:hover {
      opacity: 0;
      height: 0;
      transition-duration: 0s;
    }
  }
  .viewer-pdf-view-wrapper {
    position: relative;
    overflow: hidden;
    background: #69696c;
    display: flex;
    flex-flow: column;
  }
  .viewer-pdf-view-wrapper-content {
    flex-grow: 1;
    overflow: scroll;
  }
  .viewer-pdf-view-wrapper-loading {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    align-items: center;
    z-index: 100;
  }
  .viewer-pdf-view-wrapper-loading-icon {
    opacity: 0.4;
    font-size: 50px;
  }
  .viewer-pdf-view-area-wrapper {
    height: 100%;
  }
  .viewer-pdf-view-area-wrapper-password {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .viewer-pdf-view-area-wrapper-canvas-area {
    position: relative;
    margin: auto;
  }
  // ツールバー
  .viewer-pdf-view-area-wrapper-toolbar-spacer {
    width: 10px;
  }
  .viewer-pdf-view-area-wrapper-toolbar-spacer-s {
    width: 5px;
  }
  .viewer-pdf-view-area-wrapper-toolbar-wrapper {
    color: #ffffffe0;
  }
  .viewer-pdf-view-area-wrapper-toolbar-mainbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    padding: 0 10px;
    background: #2e2e38;
  }
  .viewer-pdf-view-area-wrapper-toolbar-left {
    display: flex;
    align-items: center;
  }
  .viewer-pdf-view-area-wrapper-toolbar-center {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
  }
  .viewer-pdf-view-area-wrapper-toolbar-right {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
  }
  .viewer-pdf-view-area-wrapper-toolbar-pagesize {
    margin-left: 4px;
    color: #ffffff9c;
  }
  .viewer-pdf-view-area-wrapper-toolbar-scalearea {
    display: flex;
    align-items: center;
    height: 100%;
    background: #0000003b;
  }
  .viewer-pdf-view-area-wrapper-toolbar-scalebutton {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 8px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
  .viewer-pdf-view-area-wrapper-toolbar-scale {
    font-size: 12px;
  }
  .viewer-pdf-view-area-wrapper-toolbar-expand-button {
    display: flex;
    align-items: center;
    width: 25px;
    justify-content: center;
    height: 100%;
    font-size: 14px;
    transition: 0.2s;
    cursor: pointer;
    background: #0000003b;

    &:hover {
      opacity: 0.8;
    }
  }
  .viewer-pdf-view-area-wrapper-toolbar-rotate {
    display: flex;
    align-items: center;
    background: #0000003b;
    padding: 6px;
    border-radius: 50px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  .viewer-pdf-view-area-wrapper-toolbar-rotatenumber {
    margin-left: 5px;
    font-size: 12px;
  }
  .viewer-pdf-view-area-wrapper-toolbar-upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #294690;
    padding: 8px 10px;
    border-radius: 4px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  .viewer-pdf-view-area-wrapper-toolbar-download-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #294690;
    color: #ffffffe0;
    padding: 8px 10px;
    border-radius: 4px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      color: #ffffffe0;
      opacity: 0.8;
    }
    &:focus {
      color: #ffffffe0;
      opacity: 0.8;
    }
  }
  .viewer-pdf-view-area-wrapper-toolbar-deletebutton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #B63939;
    padding: 8px 10px;
    border-radius: 4px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  .viewer-pdf-view-area-wrapper-toolbar-toolbarbutton {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 2px;
    font-size: 18px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
  .viewer-pdf-view-area-wrapper-toolbar-filename {
    font-size: 12px;
    width: 120px;
    opacity: 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .viewer-pdf-view-area-wrapper-toolbar-subbar {
    background: #2b2b2f;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .viewer-pdf-view-area-wrapper-toolbar-subleft {
    padding-left: 10px;
  }
  .viewer-pdf-view-area-wrapper-toolbar-subright {
    display: flex;
    justify-content: flex-end;
    height: 100%;
  }
  // パスワードフォーム
  .viewer-pdf-view-area-wrapper-password-form-wrapper {
    color: #ffffffe0;
    background: #38373d;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 0 8px #13163b94;
  }
  .viewer-pdf-view-area-wrapper-password-form-title {
    margin-bottom: 10px;
  }
  .viewer-pdf-view-area-wrapper-password-form-subtitle {
    margin-bottom: 10px;
    color: #ffffffa0;
  }
  .viewer-pdf-view-area-wrapper-password-form-input {
    margin-bottom: 10px;
    color: #38373d;
    padding: 5px;
    width: 100%;
    border-radius: 4px;

    &::placeholder {
      color: #b1b1b6;
    }
    &::-ms-input-placeholder {
      color: #b1b1b6;
    }
    &:-ms-input-placeholder {
      color: #b1b1b6;
    }
  }
  .viewer-pdf-view-area-wrapper-password-form-button {
    background: #294790;
    padding: 5px 10px;
    border-radius: 100px;
    text-align: center;
    cursor: pointer;
  }

  // 画像ビューアー
  .image-container-view {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    &:hover {
      cursor: ${(): string => "pointer"};
    }
  }

  .image-dropzone-view {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #eee;
    border: 4px dashed #aaa;
    opacity: 0;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.8;
    }

    & > p {
      font-weight: bold;
      font-size: 1.2em;
      text-align: center;
    }
  }

  .image-toolbar-view {
    background-color: ${colors.main};

    height: 32px;
    width: 100%;
    padding: 0 8px;

    display: flex;
    align-items: center;

    font-size: 22px;
    color: ${colors.white};

    > * {
      margin: 5px;
    }

    a {
      color: inherit;
    }

    &:hover {
      cursor: auto; // 本体の画像のcursorがpointerの時、アイコンがない領域のcursorもpointerになるのを回避
    }
  }

  .image-frame-view {
    position: relative;
    background-color: rgba(0, 0, 0, 0.1);
    overflow: hidden;

    height: ${(): string => normalizeSize(500)};

    & > *:first-child {
      max-height: 100%;
      max-width: 100%;
      ${!isSafari() ? "object-fit: contain;" : ""};
    }

    // img は、回転時に正しいサイズを取る必要があるため、大きさを維持
    // PDFとplaceholderは外枠いっぱいに広げる
    & > div, object {
      width: 100%;
      height: 100%;
    }
  }

  .image-placeholder-view {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .image-loading-view {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  .image-image-view {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  .image-overlay-view {
    position: absolute;
    width: 100%;
    height: 100%;
    color: transparent;
    top: 0;
    left: 0;
  }

  button.secondary {
    min-width: 120px;
    border: none;
    outline: none;
    border-radius: 8px;
    padding: 6px 16px 4px; // 文字が上下中心になる設定
    transition: 150ms;
    background: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-weight: ${fonts.weight.bold};
    color: ${colors.renewaled.textBlack};
    background: linear-gradient(180deg, #FAFAFA 0%, #F2F2F2 100%);
    box-shadow: 0px 0.5px 1.5px rgba(56, 127, 255, 0.25);
    border: 1px solid #bbbbbb;
    &:hover, &:focus {
      box-shadow: none;
      filter: brightness(98%);
    }
  }

  // 右ドロワー /components/renewaled_ui/single_views/RightDrawer/index.tsx
  .right-drawer {
    @keyframes slideIn {
      0% {
        right: ${({ width }): string => `-${width}px`};
      }
      100% {
        right: 0;
      }
    }
    // wrapperにpointer-events: none;を指定したため追加
    pointer-events: auto;
    animation: slideIn 80ms ease 1;
    position: fixed;
    right: 0;
    background-color: #fff;
    // サイドバー:1000, モーダル:1050, ヘッダー:1025
    z-index: 1030;
    box-shadow: 0 0 12px rgb(0 0 0 / 18%);
    overflow-y: auto;
    width: 1000px;
    // 170px: サイドバー幅
    max-width: calc(100vw - 170px);
    overflow-x: hidden;
    top: 0px;
    height: 100vh;
    bottom: 60px;
    color: ${colors.renewaled.textBlack};
    ${widthTransitionStyle}

    // コンポーネントは./RightDrawerHeader.tsx
    .right-drawer-header {
      animation: slideIn 80ms ease 1;
      background-color: #fff;
      position: fixed;
      z-index: 1031;
      top: 0;
      right: 0;
      width: 1000px;
      height: 40px;
      box-shadow: 0 1px 0 ${colors.renewaled.borderGrey};
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 24px;
      max-width: calc(100vw - 170px); // 170px: サイドバー幅
      box-shadow: 0 -4px 12px rgb(0 0 0 / 18%);
      ${widthTransitionStyle}

      > .right-drawer-title {
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    // コンポーネントは./RightDrawerFooter.tsx
    .right-drawer-footer {
      animation: slideIn 80ms ease 1;
      background-color: #fff;
      position: fixed;
      z-index: 1031;
      bottom: 0;
      right: 0;
      width: 1000px;
      height: 60px;
      display: flex;
      align-items: center;
      padding: 0 24px;
      // 170px: サイドバー幅
      max-width: calc(100vw - 170px);
      box-shadow: 0 4px 12px rgb(0 0 0 / 18%);
      ${widthTransitionStyle}
    }
    .right-drawer-body {
      padding: 24px;
      padding-top: 64px;
      padding-bottom: 0;
    }
    .right-drawer-header-toolbar {
      display: flex;
      align-items: center;
    }
    .prev-next-icons {
      display: flex;
    }
    .prev-icon, .next-icon, .close-button {
      color: ${colors.renewaled.textGrey};
      height: 36px;
      width: 36px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
      transition: 150ms;
      margin-left: 4px;
      :hover {
        background: ${colors.renewaled.whiteHover};
      }
      &.disabled {
        color: ${colors.renewaled.borderGrey};
        cursor: auto;
        :hover {
          background: transparent;
        }
      }
    }
    .close-button {
      margin-left: 16px;
    }
    .overlay {
      z-index: 1032;
      position: fixed;
      top: 40px;
      bottom: 0;
      width: 700px;
      background-color:rgba(255,255,255,0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      > i {
        font-size: 32px;
        color: #808080;
      }
    }
  }

  // 経費分割の開始ボタン // applications/expenses/split/components/buttons/SplitStartDropdownButton.tsx
  .split-start-dropdown-button {
    position: relative;
    .dropdown-toggle,
    .dropdown-item > a {
      color: ${colors.renewaled.textBlack};
      text-decoration: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding: 10px 20px;
      box-shadow: none !important;
      &:hover {
        background-color: ${colors.renewaled.whiteHover};
      }
    }
    .create-menu-item-link:focus,
    .dropdown-item > a:focus {
      transition: 100ms;
      color: ${colors.renewaled.main};
      background-color: ${colors.renewaled.whiteHoverActive};
      font-weight: 600;
    }
    // hoverでDropdownを表示するようにする
    &:hover > .dropdown-menu {
      display: block;
    }
    .dropdown-menu {
      position: absolute;
      bottom: -8px;
      top: auto;
      left: 140px;
      width: 100px;
      padding: 4px 0;
      border: none;
    }
    .dropdown-item > a {
      border-radius: 0;
      display: block;
    }
  }

  // ドロップダウンメニュー components/renewaled_ui/Dropdown/MenuItem.tsx
  .drop-down-menu-item {
    margin-left: 0 !important;
    i {
      width: 20px;
      text-align: center;
      margin-right: 10px;
      font-size: 16px;
    }
    // aタグにデフォルトスタイルがあるので!importantで上書き
    a {
      display: flex !important;
      align-items: center !important;
      padding: 10px 20px !important;
      font-weight: ${fonts.weight.bold} !important;
      width: 100% !important;
      outline: none !important;
    }

    // id='label'の項目に付与
    #label {
      min-width: 280px;
      :hover,
      :focus {
        cursor: auto;
        background: none;
      }
    }
    &.disabled > a {
      color: gray;
    }
  }

  // 経費テーブル内のLabelIconsWithOverlay applications/expenses/tables/ExpenseTable/LabelIconsWithOverlay.tsx
  .label-icons-with-overlay {
    display: flex;
    align-items: center;
    height: 100%;

    > i, > div {
      margin-left: 2px;
      margin-right: 2px;
    }

    > div {
      display: flex;
      align-items: center;
    }

    .material-icons {
      font-size: 14px;
    };
  }

  // ToolTip components/renewaled_ui/ToolTip/ToolTip.tsx
  .renewaled-tool-tip {
    position: relative;
    display: inline-block;
  }
  .tool-tip {
    z-index: 200;
    border-radius: 4px;
    font-size: 12px;
    white-space: pre;
    color: #FFFFFF;
    background: rgba(0, 1, 19, 0.7);
    backdrop-filter: saturate(180%) blur(10px);
    position: absolute;
    margin-top: 4px;
    .tool-tip-content {
      padding: 4px 8px;
    }
    &.center {
      right: 50%;
      transform: translateX(50%);
      transform-origin: 100% -100%;
    }
    &.right {
      right: 0;
      transform: translateX(0%);
      transform-origin: 100% -100%;
    }
    &.left {
      left: 0;
      transform: translateX(0%);
      transform-origin: 0 -100%;
    }
    animation-duration: 100ms;
    animation-fill-mode: forwards;
  }

  // 解像度検証結果
  .resolution-informations-viewer {

    // トップサマリー
    .resolution-information-top-summary {
      a::hover {
        cursor: pointer;
      }
    }

    // ドロワー内部
    .right-drawer {
      width: 350px;

      h5 {
        font-weight: bold;
        margin: 15px 0;
      }

      .foreside-area, .backside-area {
        padding-left: 5%;

        .side-detail-item {
          list-style: none;

          .ver-text {
            margin: 5px 0;
          }

          .info-area {
            width: calc(100% - 25px);
            text-align: right;
          }
        }
      }

      .foreside-area {
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 15px;
      }
    }
  }

  .tax-category-table-fields {
    .header-fields {
      display: flex;
      .tax-category-label-fields {
        width: 50%;
      }
      .amount-label-fields {
        width: 50%;
        display: flex;
        justify-content: space-between;
        .amount-column {
          white-space: nowrap;
          width: 100%;
        }
        .with-tax {
          padding-right: 9px;
          justify-content: right;
        }
      }
      &.uneditable {
        background: #f4f4f4;
        padding: 4px 0 2px 8px;
        border-radius: 4px;
        margin-bottom: 4px;
      }
    }
    .tax-category-row {
      display: flex;
      align-items: center;
      padding: 4px 0;
      border-bottom: 1px solid ${colors.renewaled.borderGrey};
      .tax-category-fields {
        width: 50%;
        display: flex;
        .name-column {
          overflow-wrap : break-word;
          width: calc(50% - 30px);
          min-width: 90px;
          margin-right: 8px;
          margin-left: 4px;
        }
        .rate-column {
          width: 30px
          margin-right: 8px;
        }
      }
      .amount-fields {
        width: 50%;
        display: flex;
        .amount-column {
          white-space: nowrap;
          margin-right: 4px;
          width: 100%;
        }
      }
      :nth-child(1) {
        border-top: 1px solid ${colors.renewaled.borderGrey};
      }
      &.uneditable {
        padding: 4px 8px 2px 4px;
      }
    }
  }
  // PDFビューアーテキストレイヤー
  ${PDFTextLayerWrapperCSS}
`;
