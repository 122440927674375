import { Pagination } from "components/renewaled_ui/DataFetchingTable/molecules/defaultMode/Pagination";
import { SizePerPageSelector } from "components/renewaled_ui/DataFetchingTable/molecules/defaultMode/SizePerPageSelector";
import { PaginationNew } from "components/renewaled_ui/DataFetchingTable/molecules/simpleMode/PaginationNew";
import { SelectedItemsMenu } from "components/renewaled_ui/DataFetchingTable/molecules/simpleMode/SelectedItemsMenu";
import { SizePerPageSelectorNew } from "components/renewaled_ui/DataFetchingTable/molecules/simpleMode/SizePerPageSelectorNew";
import React from "react";
import styled from "styled-components";

const TableBottomToolBar = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  position: relative;
`;
const TableBottomToolBarPages = styled.div`
  display: flex;
  align-items: center;
`;
interface BottomItemsProps {
  readonly isReportItemDesignMode: boolean;
}
const BottomItems = styled.div<BottomItemsProps>`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  ${(p): string => (p.isReportItemDesignMode ? `
    margin-top: 0;

    .table-pagination {
      flex-grow: 1;
      justify-content: center;
    }
  ` : "" )}
`;

interface Props {
  /** 枠線がないシンプルなデザイン */
  readonly isSimpleDesignMode?: boolean;
  /** 請求書刷新でのテーブルデザイン */
  readonly isReportItemDesignMode: boolean;
  /** ページネーションを表示させるか */
  readonly isShowPagination: boolean;
  /** 1ページ当たりの表示件数 */
  readonly sizePerPage: number;
  /** ページネーションの最大数 */
  readonly paginationSize: number;
  /** 現在のページ */
  readonly currentPage: number;
  /** 1ページ当たりの表示件数を隠すか */
  readonly hideSizePerPage?: boolean;
  /**
   * テーブル左下の１ページあたりのデータ取得結果を表示するか
   * 一部APIで、クエリで指定する検索結果の数と実際に返ってくる数に差がある場合があり、
   * 合わない箇所については暫定的に結果の数を隠せる(falseにする)ためのprops
   */
  readonly showItemsCount?: boolean;
  /** 1ページ当たりの表示件数の選択肢 */
  readonly sizePerPageList: number[];
  /** 現在のページの表示件数 */
  readonly currentPageDataLength: number;
  /** 表示件数 */
  readonly dataLength: number;
  /** 表示件数 */
  readonly selectedItemsLength: number;
  /** 選択した項目の操作ドロップダウン内に表示するDOM */
  readonly renderSelectedItemsControlMenu?: () => JSX.Element;
  /** ページが変更された */
  readonly onPageChange: (page: number, sizePerPage: number) => void;
  /** 1ページ当たりの表示件数を変更した際のハンドラ */
  readonly onSizePerPageList: (sizePerPage: number) => void;
}

/**
 * テーブル下のツールバー
 */
export const TableToolBar: React.FC<Props> = (props) => {
  // 機能が多いツールバーの場合
  if (props.isSimpleDesignMode) {
    return (
      <TableBottomToolBar className="table-bottom-tool-bar">
        <TableBottomToolBarPages className="table-page-display">
          {!props.hideSizePerPage && (
            <SizePerPageSelectorNew
              sizePerPage={props.sizePerPage}
              currentPage={props.currentPage}
              showItemsCount={props.showItemsCount}
              onSizePerPageList={props.onSizePerPageList}
              sizePerPageList={props.sizePerPageList}
              currentPageDataLength={props.currentPageDataLength}
              dataLength={props.dataLength}
            />
          )}{" "}
          <SelectedItemsMenu
            selectedItemsLength={props.selectedItemsLength}
            renderSelectedItemsControlMenu={
              props.renderSelectedItemsControlMenu
            }
          />
        </TableBottomToolBarPages>

        {props.isShowPagination && (
          <PaginationNew
            onPageChange={props.onPageChange}
            currentPage={props.currentPage}
            paginationSize={props.paginationSize}
            sizePerPage={props.sizePerPage}
          />
        )}
      </TableBottomToolBar>
    );
  }

  return (
    <BottomItems isReportItemDesignMode={ props.isReportItemDesignMode }>
      { !props.hideSizePerPage && <SizePerPageSelector
        sizePerPage={ props.sizePerPage }
        onSizePerPageList={ props.onSizePerPageList }
        sizePerPageList={ props.sizePerPageList }
        currentPageDataLength={ props.currentPageDataLength }
      /> }

      { props.isShowPagination && (
        <Pagination
          onPageChange={ props.onPageChange }
          currentPage={ props.currentPage }
          paginationSize={ props.paginationSize }
          sizePerPage={ props.sizePerPage }
        />
      )}
    </BottomItems>
  );
};
